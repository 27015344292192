import React from 'react'
import Layout from "components/Layout";
import styled from "@emotion/styled";

const ThankYouWrapper = styled("div")`
    text-align: center;
    padding: 5em 0;
`

export default () => (
  <Layout>
      <ThankYouWrapper>
        <h1>Thank you!</h1>
        <p>I look forward to connecting!</p>
      </ThankYouWrapper>
  </Layout>
)